import image1 from "../../img/imp-digitales/001.jpg";
import image2 from "../../img/imp-digitales/002.jpg";
import image3 from "../../img/imp-digitales/003.jpg";
import image4 from "../../img/imp-digitales/004.jpg";
import image5 from "../../img/imp-digitales/005.jpg";
import image6 from "../../img/imp-digitales/006.jpg";
import image7 from "../../img/mesaPortada.png";




const ImgDigital = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
];

export default ImgDigital;